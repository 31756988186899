import { FC, useEffect } from 'react';
import { Layout, PageHeader } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import routes from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import './App.less';
import { checkDevice } from 'store/GlobalStates/GlobalStates';
import CustomContent from 'components/CustomComponents/content/CustomContent';
import CustomHeader from 'components/CustomComponents/header/CustomHeader';
import CustomPageHeader from 'components/CustomComponents/pageHeader/CustomPageHeader';
import CustomFooter from 'components/CustomComponents/footer/CustomFooter';

const App: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  useEffect(() => {
    //checking device
    dispatch(checkDevice());
  }, []);

  if (location.pathname === '/not-found') {
    return (
      <Layout className='App'>
        <CustomContent />
      </Layout>
    );
  }

  return (
    <Layout className='App'>
      <Header className='App-header'>
        <CustomHeader />
      </Header>
      <PageHeader className='App-pageHeader'>
        <CustomPageHeader />
      </PageHeader>
      <Content className='App-content'>
        <CustomContent />
      </Content>
      <Footer className='App-footer'>
        <CustomFooter />
      </Footer>
    </Layout>
  );
};

export default App;
