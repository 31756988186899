import { Row, Col, Spin } from 'antd';
import React from 'react';

export default function Spinner() {
  return (
    <Row className='mt-50 mb-50' justify={'center'}>
      <Col>
        <Spin tip='Loading...' size='default' />
      </Col>
    </Row>
  );
}
