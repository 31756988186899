import React, { useEffect, useState } from 'react';
import siyahArkaPlanHeader from '../../../assets/images/siyah-arka-plan-header.png';
import siyahArkaPlanProduct from '../../../assets/images/siyah-arka-plan-product.png';
import { isStringEmpty } from 'helpers/methods';

export default function CustomImageViewer(props: {
  imageUrl: string;
  alt?: string;
  width?: string;
  className?: string;
  type?: string;
}) {
  const { imageUrl, alt, width, className, type } = props;
  const [src, setSrc] = useState<string>();
  useEffect(() => {
    setSrc(process.env.REACT_APP_IMAGE_API_URL_KWS + imageUrl);
  }, [imageUrl]);
  return (
    <img
      onError={() => {
        console.log('an error occoured while loading image ' + type + ' => ' + imageUrl);
        if (type == 'product') {
          setSrc(siyahArkaPlanProduct);
        } else if (type == 'header') {
          setSrc(siyahArkaPlanHeader);
        }
      }}
      src={src}
      width={width}
      alt={alt}
      className={className}
    />
  );
}
