import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { INewsItem, INewsListRequest, INewsListResponse } from 'store/interfaces';
import { createApiCall as createApiCall } from 'store/service';

export const newsList = createAsyncThunk(
  'newsList',
  async ({ ...props }: INewsListRequest, thunkAPI) => {
    const { onSuccess, onError, body } = props;
    try {
      const response: INewsListResponse = await createApiCall({
        method: 'POST',
        url: 'news/list',
        body,
      });
      response.success ? onSuccess(response.data) : onError(response);
      return response.data;
    } catch (error: unknown) {
      onError();
      console.log('newsList CreateAsyncThunk', error);
      thunkAPI.rejectWithValue(error);
    }
  },
);
//create newsList slice start
export const newsListSlice = createSlice({
  name: 'newsList',
  initialState: {
    news: (<any>[]) as Array<INewsItem> | undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(newsList.fulfilled, (state, action: any) => {
      state.news = action.payload;
    });
  },
});
// create newsList slice end

export default newsListSlice.reducer;
