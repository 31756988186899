import { Button, Col, Row } from 'antd';
import { notFoundImage } from 'assets/images/_index';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Row className='full-height pv-40-px bg-broken-white' justify={'center'}>
      <Col className='bg-white pv-100-px ' span={22}>
        <Row>
          <Col span={24}>
            <Row justify={'center'}>
              <Col>
                <img src={notFoundImage} alt='' />
              </Col>
            </Row>
          </Col>
          <Col span={24} className='text-center mt-32-px mb-12-px'>
            <span className='text fs-28-px fw-700'>Üzgünüz!</span>
          </Col>
          <Col span={24} className='text-center'>
            <span className='text fs-22-px fw-400'>Sayfa Bulunamadı. </span>
          </Col>
          <Col className='mt-60-px' span={24}>
            <Row justify={'center'}>
              <Col>
                <Button onClick={() => navigate('/')} className='as-btn fs-18-px ph-41-px pv-10-px'>
                  Anasayfaya Git
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
