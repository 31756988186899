import { Button, Col, Image, Row } from 'antd';
import { logoBUBU } from 'assets/images/_index';
import { baseRequestBody } from 'helpers/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'store';
import { INavBarItem } from 'store/interfaces';
import { navbar } from 'store/NavBar/NavBarList';

export default function CustomHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [navbarItems, setNavbarItems] = useState<Array<INavBarItem>>([]);
  const dispatch = useDispatch<AppDispatch>();
  //navbar
  useEffect(() => {
    dispatch(
      navbar({
        body: baseRequestBody,
        onSuccess: (data: INavBarItem[]) => {
          setNavbarItems(data);
        },
        onError: () => {
          // do nothing.
        },
      }),
    );
  }, []);
  const handleButtonClick = (redirectUrl: string) => {
    navigate(redirectUrl);
  };

  return (
    <Row className='header' justify={'center'}>
      <Col span={22}>
        <Row justify={'space-between'}>
          <Col>
            <Image
              onClick={() => handleButtonClick('/')}
              preview={false}
              src={logoBUBU}
              alt='logo'
              className='logo cursor-pointer'
            />
          </Col>
          <Col>
            <Row className='header-buttons'>
              {navbarItems.map((button) => (
                <Col key={button.name}>
                  <Button
                    onClick={() => handleButtonClick(button.url)}
                    className={`header-buttons-button text text-center fs-14-px fw-500${
                      location.pathname === button.url ? ' active' : ''
                    }`}
                  >
                    {button.name}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
