import { Col, Row } from 'antd';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from 'routes/routes';

export default function CustomContent() {
  const element = useRoutes(routes());
  return (
    <Row className='mv-12-px' justify={'center'}>
      <Col span={22}>{element}</Col>
    </Row>
  );
}
