import { Col, Row } from 'antd';
import {
  IconDropdownClosedWhite,
  IconDropdownOpen,
  IconDropdownArrowUp,
  IconDropdownArrowDown,
} from 'assets/icons/_index';

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
type Props = {
  onClick: any;
  isOpen: boolean;
  text: string;
};
const DropdownButton: FC<Props> = ({ text, isOpen, onClick }) => {
  const isMobilDevice = useSelector((state: RootState) => state.globalStates.isMobilDevice);
  return (
    <Row>
      {isMobilDevice ? (
        <Col
          onClick={() => onClick(!isOpen)}
          className={`dropdownButton${isOpen ? ' open' : ''} pv-10-px pl-12-px pr-8-px`}
          span={24}
        >
          <Row className='a-center' justify={'space-between'}>
            <Col span={21}>
              <span className={`text fs-12-px ${isOpen ? 'text-white' : ''}`}>{text}</span>
            </Col>
            <Col span={2}>
              <Row justify={'end'}>
                <img
                  alt=''
                  className='img-auto-resize w-50 h-50'
                  src={isOpen ? IconDropdownClosedWhite : IconDropdownOpen}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col
          onClick={() => onClick(!isOpen)}
          className={`dropdownButton${isOpen ? ' open' : ''} pv-13-px pl-29-px pr-16-px`}
          span={24}
        >
          <Row className='a-center' justify={'space-between'}>
            <Col span={22}>
              <span className={`text fs-14-px ${isOpen ? 'text-white' : ''}`}>{text}</span>
            </Col>
            <Col span={1}>
              <Row justify={'end'}>
                <img
                  alt=''
                  className='img-auto-resize'
                  src={isOpen ? IconDropdownArrowUp : IconDropdownArrowDown}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default DropdownButton;
