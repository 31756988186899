import { FormInstance } from 'antd';
import { customerTypeIds, monthsTR } from './constants';
import { stringOrNumber, customerTypes } from './global';
import moment, { Moment } from 'moment';
import siyahArkaPlanHeader from '../assets/images/siyah-arka-plan-header.png';

//redirect new window
export const redirectInNewWindow = (url: string) => {
  window.open(url, '_blank');
};

export const openPdfInNewWindow = (url: string) => {
  redirectInNewWindow(process.env.REACT_APP_PDF_API_URL_KWS + url + '.pdf');
};

//dynamic image import
export const getHeaderImageByImageUrl = (imageUrl: string) => {
  let img = siyahArkaPlanHeader;
  try {
    /* eslint-disable */
    img = require('../assets/images/' + imageUrl + '.png');
  } catch (error) {
    console.log(error);
  }
  return img;
};

//check substring
export const isSubstringOf = (_string: string, value: string | undefined) => {
  return value && _string.includes(value);
};

//empty string check
export const isStringEmpty = (value: string | any) => {
  return value == undefined || value == null || value.trim() == '';
};
//splitting dateandtime string
export const splitDateAndTimeString = (dateAndTime: string) => {
  const dateAndTimeArray = dateAndTime.split('T');
  const date = dateAndTimeArray[0];
  const time = dateAndTimeArray[1];
  const dateArray = date.split('-');
  const day = dateArray[0];
  const month = dateArray[1];
  const year = dateArray[2];
  const timeArray = time.split(':');
  const hour = timeArray[0];
  const minute = timeArray[1];
  const second = timeArray[2];
  return { dates: { date, day, month, year }, times: { time, hour, minute, second } };
};
//returning day and month in '01 Ocak' format
export const getDayAndMonthTR = (dateString: string) => {
  const splittedDateAndTimeObject = splitDateAndTimeString(dateString);
  return `${splittedDateAndTimeObject.dates.day} ${
    monthsTR[+splittedDateAndTimeObject.dates.month - 1]
  }`;
};

// Format Number to TRY Currency Start
export const currencyFormat = (number: stringOrNumber, type = 'label') => {
  if (number && type === 'label') {
    return (
      new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2 }).format(Number(number)) + ' ₺'
    );
  } else if (number && type === 'input') {
    return new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2 }).format(Number(number));
  } else {
    return type === 'label' ? '- ₺' : ' ';
  }
};
// Format Number to TRY Currency End

// Tax ID Validator Start
const taxIdValidator = (taxId: string) => {
  if (taxId.length === 10) {
    const v = [];
    const lastDigit = Number(taxId.charAt(9));
    for (let i = 0; i < 9; i++) {
      const tmp = (Number(taxId.charAt(i)) + (9 - i)) % 10;
      v[i] = (tmp * 2 ** (9 - i)) % 9;
      if (tmp !== 0 && v[i] === 0) v[i] = 9;
    }
    const sum = v.reduce((a, b) => a + b, 0) % 10;
    return (10 - (sum % 10)) % 10 === lastDigit;
  }
  return false;
};
// Tax ID Validator End

// TC ID Validator Start
export const tcIdValidator = (tcId: string) => {
  tcId = String(tcId);

  let singleDigit;
  let doubleDigit;

  if (tcId.substring(0, 1) === '0') {
    return false;
  }

  if (tcId.length !== 11) {
    return false;
  }

  const firstTenDigitsArray = tcId.substr(0, 10).split('');
  let firstTenDigitsTotal = (singleDigit = doubleDigit = 0);

  for (let i = 0; i < 9; ++i) {
    const j = parseInt(firstTenDigitsArray[i], 10);
    if (i & 1) {
      doubleDigit += j;
    } else {
      singleDigit += j;
    }
    firstTenDigitsTotal += j;
  }

  if ((singleDigit * 7 - doubleDigit) % 10 !== parseInt(tcId.substr(-2, 1), 10)) {
    return false;
  }

  firstTenDigitsTotal += parseInt(firstTenDigitsArray[9], 10);

  return firstTenDigitsTotal % 10 === parseInt(tcId.substr(-1), 10);
};
// TC ID Validator End

// Pol Validator Start
export const polValidator = (pol: string) => {
  return pol.match('^[0-9]*$') && pol.length === 11 ? true : false;
};
// Pol Validator End

// Pol Validator Start
export const kknValidator = (kkn: string) => {
  return kkn.length === 5 || kkn.length === 6 ? true : false;
};
// Pol Validator End

// Skn Validator Start
const sknValidator = (skn: string) => {
  return skn.length === 16 ? true : false;
};
// Skn Validator End

// tckn case start
const tcknCase = (value: string) => {
  if (tcIdValidator(value) && (!value.startsWith('99') || !value.startsWith('98'))) {
    return Promise.resolve();
  } else {
    return Promise.reject('Lütfen geçerli bir T.C Kimlik Numarası giriniz.');
  }
};
// tckn case end

// ykn case start
const yknCase = (value: string) => {
  if ((value.startsWith('99') || value.startsWith('98')) && tcIdValidator(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject('Lütfen geçerli bir Yabancı Kimlik Numarası giriniz.');
  }
};
// ykn case end

// vkn case start
const vknCase = (value: string) => {
  if (taxIdValidator(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject('Lütfen geçerli bir Vergi Kimlik Numarası giriniz.');
  }
};
// vkn case end

// skn case start
const sknCase = (value: string) => {
  if (sknValidator(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject('Lütfen geçerli bir Sağlık Kart Numarası giriniz.');
  }
};
// skn case end

// pol case start
const polCase = (value: string) => {
  if (polValidator(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject('Lütfen geçerli bir Poliçe Numarası giriniz.');
  }
};
// pol case end

// external number case start
const externalNumberCase = (value: string) => {
  if (!isNaN(parseInt(value)) && parseInt(value).toString().length === value.length && !!value) {
    return Promise.resolve();
  } else {
    return Promise.reject('Lütfen geçerli bir müşteri numarası giriniz.');
  }
};
// external number case end

// kkn number case start
const kknCase = (value: string) => {
  if (kknValidator(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject('Lütfen geçerli bir Kıbrıs Kimlik Numarası giriniz.');
  }
};
// kkn number case end

// identity validator case end
const identityValidatorCases = (idType: string, value: string) => {
  switch (idType) {
    case customerTypeIds.tckn:
      return tcknCase(value);
    case customerTypeIds.ykn:
      return yknCase(value);
    case customerTypeIds.vkn:
      return vknCase(value);
    case customerTypeIds.skn:
      return sknCase(value);
    case customerTypeIds.pol:
      return polCase(value);
    case customerTypeIds.externalNumber:
      return externalNumberCase(value);
    case customerTypeIds.kkn:
      return kknCase(value);
    default:
      return Promise.reject('Lütfen geçerli bir kimlik numarası giriniz.');
  }
};
// identity validator case end

// Identity Validator Start
export const identityValidator = (idType: string, value: string) => {
  if (idType) {
    if (value) {
      return identityValidatorCases(idType, value);
    } else {
      return Promise.reject(getIdentityMessage(idType));
    }
  } else {
    return Promise.reject('Lütfen kimlik tipi seçiniz.');
  }
};

const getIdentityMessage = (idType: string) => {
  const messageDetail = idType.toUpperCase() === 'POL' ? 'Poliçe Numarası' : idType.toUpperCase();
  return idType != 'externalNumber'
    ? `Lütfen ${messageDetail} giriniz.`
    : 'Lütfen müşteri numarası giriniz.';
};
// Identity Validator End

// Max length function for input according to radio value start
export const validateLengthIdentityType = (
  identityTypeValue: string,
  customerTypes: customerTypes,
) => {
  if (identityTypeValue === customerTypes.tckn || identityTypeValue === customerTypes.ykn) {
    return 11;
  } else if (identityTypeValue === customerTypes.vkn) {
    return 10;
  } else if (identityTypeValue === customerTypes.externalNumber) {
    return 9;
  } else if (identityTypeValue === customerTypes.skn) {
    return 16;
  } else if (identityTypeValue === customerTypes.pol) {
    return 10;
  } else return null;
};
// Max length function for input according to radio value end

//email valitadot start
export const emailValidator = (mail: string) => {
  return (
    String(mail)
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
      ? true
      : false
  );
};
//email valitadot end

// get base64 start
export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
// get base64 end

// Download file which format is binary function start
export const downloadFileFromBlobData = (data: File, contentType: string, fileName: string) => {
  const blob = new Blob([data], { type: contentType });
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = fileName;
  downloadLink.click();
};
// Download file which format is binary function end

// useStep function for step components start
//TODO: Burası incelenecek gerekli mi bakılacak
export const useStep = () => {
  const params = new URLSearchParams(window.location.search);
  const step = params.get('adim');
  return step ? parseInt(step, 10) : 0;
};
// useStep function for step components end

// On Key press event for disable characters for input start
export const abortCharacterAccordingToRegex = (
  event: React.KeyboardEvent<HTMLInputElement>,
  regex: string,
) => {
  const specialCharRegex = new RegExp(regex);
  const pressedKey = event.key;
  if (!specialCharRegex.test(pressedKey) && pressedKey !== 'Backspace' && pressedKey !== 'Tab') {
    event.preventDefault();
    return false;
  } else return true;
};
// On Key press event for disable characters for input end

// Get age when the date of birth (number or string -> date = year, month, day) is entered start
export const calculateAge = (date: string) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

  return age;
};
// Get age when the date of birth (number or string -> date = year, month, day) is entered end

// Check the env is appropriate to use dev tools - start
export const shouldDevToolsEnabled = () => {
  // eslint-disable-next-line no-undef
  return process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'test';
};
// Check the env is appropriate to use dev tools - end

// Generate random key start
export const generateRandomKey = (): number => {
  const arr = new Uint32Array(1);

  const num = window.crypto.getRandomValues(arr)[0];
  if (num.toString().length !== 10) {
    return generateRandomKey();
  }
  return num;
};
// Generate random key end

// ===== DATE METHODS START =====

export const disabledDateForMonth = (current: moment.Moment) => {
  const today = moment().format('D');
  const endOfMonth = moment().endOf('month').format('D');

  return (
    current.isSameOrAfter(moment().add(parseInt(endOfMonth) - parseInt(today), 'days')) ||
    current.isSameOrBefore(moment().subtract(31, 'days'))
  );
};

export const disabledDateForLastDayOfMonth = (current: moment.Moment) => {
  const today = moment().format('D');
  const endOfMonth = moment().endOf('month').format('D');

  return (
    current.isSameOrAfter(moment().add(parseInt(endOfMonth) - parseInt(today), 'days')) ||
    current.isSameOrBefore(moment().subtract(1, 'day'))
  );
};

export const disabledBeforeTodayStartDate = (current: moment.Moment) => {
  return current && current < moment().startOf('day');
};

export const disabledTodayAndAfter = (current: moment.Moment) => {
  const customDate = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && current < moment(customDate, 'YYYY-MM-DD');
};

export const disabledAfterTodayStartDate = (current: moment.Moment) => {
  return current && current > moment().add(1, 'day').startOf('day');
};

export const disabledAfterTodayAndSelectedDate = (
  form: FormInstance,
  current: moment.Moment,
  selectedDateFieldName: string,
) => {
  const selectedDate = form.getFieldValue(selectedDateFieldName);
  return (
    current &&
    (current > moment().add(1, 'day').startOf('day') || (selectedDate && current > selectedDate))
  );
};

export const disabledAfterTodayAndBeforeSelectedDate = (
  form: FormInstance,
  current: moment.Moment,
  selectedDateFieldName: string,
) => {
  const selectedDate = form.getFieldValue(selectedDateFieldName);
  return (
    current &&
    (current > moment().add(1, 'day').startOf('day') || (selectedDate && current < selectedDate))
  );
};

export const disabledEndDate = (
  form: FormInstance,
  current: moment.Moment,
  formItemName: string,
) => {
  const startDate = form.getFieldValue(formItemName);
  if (moment(startDate).isAfter(moment())) {
    return moment(startDate).add(1, 'day') > current;
  } else {
    return moment(startDate) > current;
  }
};
export const disabledBeforeTodayAndAfterSpecifiedDayCountThanToday = (
  current: moment.Moment,
  dayCount: number,
) => {
  const daysAfterToday = moment(new Date()).add(dayCount, 'days');
  return current && (current < moment().startOf('day') || current > daysAfterToday);
};

export const calculateDaysRemaining = (startDate: Moment, endDate: Moment) => {
  return endDate.diff(startDate, 'days');
};
// ===== DATE METHODS END =====
