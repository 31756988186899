import { Col, Row, Typography } from 'antd';
import CustomImageViewer from 'components/UIComponents/CustomImageViever/CustomImageViewer';
import { isSubstringOf } from 'helpers/methods';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import ContentNavigation from '../Navigation/ContentNavigation';
import ContentNavigationMobile from '../Navigation/ContentNavigationMobile';

export default function DefaultPageHeader() {
  const { imageUrl, text } = useSelector((state: RootState) => state.pageHeader.props);
  const isMobilDevice = useSelector((state: RootState) => state.globalStates.isMobilDevice);
  const { Text } = Typography;
  return (
    <Row justify={'center'}>
      <Col span={22}>
        <Row>
          <Col className='pageHeaderTextContainer' span={24}>
            <Text className={`text text-primary-red fs-${isMobilDevice ? 20 : 38}-px fw-700`}>
              {text}
            </Text>
          </Col>
          <Col span={24} className='contentHeader'>
            <CustomImageViewer imageUrl={imageUrl} type='header' width='100%' />
          </Col>
          <Col span={24}>
            <ContentNavigation />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
