import { Carousel, Col, Row, Typography } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useRef, useState } from 'react';

export default function InformationListBox(props: { title: string; dataList: any[] }) {
  const carouselRef = useRef<CarouselRef>(null);

  const { Text } = Typography;
  const { title, dataList } = props;
  return (
    <Row className='InformationListBox'>
      <Col span={24}>
        <Row className='InformationListBox-titleContainer' justify={'center'} align={'middle'}>
          <Col className='InformationListBox-titleContainer-title' span={22}>
            <Text className='InformationListBox-titleContainer-title-text'>{title}</Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row className='InformationListBox-contentContainer' justify={'center'} align={'middle'}>
          <Col className='InformationListBox-contentContainer-content' span={22}>
            <Carousel
              dots={{ className: 'InformationListBox-carousel-dots' }}
              ref={carouselRef}
              className='InformationListBox-carousel'
            >
              {dataList.map((data) => (
                <Row key={data.id} gutter={[0, 16]}>
                  <Col span={24}>
                    <Text className='InformationListBox-contentContainer-content-title'>
                      {data.title}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text className='InformationListBox-contentContainer-content-text'>
                      {data.description}
                    </Text>
                  </Col>
                </Row>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
