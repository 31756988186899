import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IProductDetails, IProductDetailsRequest, IProductDetailsResponse } from 'store/interfaces';
import { createApiCall as createApiCall } from 'store/service';

export const productDetails = createAsyncThunk(
  'productDetails',
  async ({ ...props }: IProductDetailsRequest, thunkAPI) => {
    const { onSuccess, onError, body } = props;
    try {
      const response: IProductDetailsResponse = await createApiCall({
        method: 'POST',
        url: 'products/details',
        body,
      });
      response.success ? onSuccess(response.data) : onError(response);
      return response.data;
    } catch (error: unknown) {
      onError();
      console.log('productList CreateAsyncThunk', error);
      thunkAPI.rejectWithValue(error);
    }
  },
);
