import { Col, Image, Row, Typography } from 'antd';
import { odullerImage } from 'assets/images/_index';
import InformationListBox from 'components/CustomComponents/informationListBox/InformationListBox';
import { baseRequestBody } from 'helpers/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { INewsItem, InformationListBoxItem } from 'store/interfaces';
import { newsList } from 'store/News/NewsList';

export default function HomePage() {
  const { Text } = Typography;
  const dispatch = useDispatch<AppDispatch>();
  const [newsDataList, setNewsDataList] = useState<InformationListBoxItem[]>([]);

  useEffect(() => {
    dispatch(
      newsList({
        body: {
          ...baseRequestBody,
        },
        onSuccess: (data: INewsItem[]) => {
          setNewsDataList(
            data.map((item: INewsItem) => {
              return { ...item, description: item.subTitle };
            }),
          );
        },
        onError: (error: any) => {
          console.log('HomePage newsList error:', error);
          setNewsDataList([]); // error handling
        },
      }),
    );
  }, []);

  return (
    <Row className='homePage'>
      <Col span={24}>
        <InformationListBox title={'Haberler'} dataList={newsDataList} />
      </Col>
      <Col span={24}>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row className='h-100' justify={'center'}>
              <Col span={23}>
                <Row className='homePage-bubu h-100' gutter={[0, 24]}>
                  <Col className='homePage-bubu-title' span={24}>
                    <Text className='homePage-bubu-title-text'>Bir Usta Bin Usta</Text>
                  </Col>
                  <Col span={24}>
                    <Text className='homePage-bubu-text'>
                      2010 yılında 85. kuruluş yılını, geniş kapsamlı bir proje ile taçlandırmak
                      isteyen Anadolu Sigorta, kurum stratejisine ve hedef kitlelerinin
                      beklentilerine uygun olarak bir sosyal sorumluluk projesi geliştirdi. Projenin
                      ismi, içeriği ve kapsamı ile en iyi örtüşecek şekilde, “Bir Usta Bin Usta”
                      olarak belirlendi.
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row justify={'center'}>
              <Col span={23}>
                <Row className='homePage-awards' gutter={[0, 24]}>
                  <Col className='homePage-awards-title' span={24}>
                    <Text className='homePage-awards-title-text'>Ödüller</Text>
                  </Col>
                  <Col span={24}>
                    <Image preview={false} src={odullerImage} alt='award' />
                  </Col>
                  <Col span={24}>
                    <Text className='homePage-awards-text'>
                      Bir Usta Bin Usta PRİDA ödül töreninde Yılın Topluma Değer Katan Projesi
                      ödülünü kazandı.
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
