import { Row, Col, Typography } from 'antd';
import HtmlStringRenderer from 'components/HtmlStringRenderer';
import CustomDropdown from 'components/UIComponents/CustomDropdown/CustomDropdown';
import React from 'react';
import { ISubProductDetails } from 'store/interfaces';

export default function ProductViewer(props: { selectedSubProduct: ISubProductDetails }) {
  const { Text } = Typography;
  const { selectedSubProduct } = props;
  return (
    <Row gutter={[0, 16]}>
      {selectedSubProduct.description ? (
        <Col span={24}>
          <Text className='text text-black fs-22-px fw-500'>{selectedSubProduct.description}</Text>
        </Col>
      ) : null}
      <Col span={24}>
        <HtmlStringRenderer html={selectedSubProduct.content} />
      </Col>
      <Col span={24}>
        <CustomDropdown items={selectedSubProduct.dropDowns} />
      </Col>
      <Col span={24}>
        <HtmlStringRenderer html={selectedSubProduct.content2} />
      </Col>
    </Row>
  );
}
