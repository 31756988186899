import axios from 'axios';
import { ICreateApiCallPropsInterface } from './interfaces';

export const createApiCall = async ({ ...props }: ICreateApiCallPropsInterface) => {
  // Necesary states start
  const { body, url, method } = props;
  const controller = new AbortController();
  // Necesary states end

  // Request default headers start
  const headers = {
    Accept: '*',
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'X-Content-Type-Options': 'NOSNIFF',
    'X-Frame-Options': 'DENY',
    'X-XSS-Protection': '1',
    'Expect-CT': 'max-age=7776000, enforce, report-uri=',
    'Referrer-Policy': 'strict-origin',
    'Strict-Transport-Security': 'max-age=31536000',
  };
  // Request default headers end

  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_API_URL_KWS,
      url,
      method,
      headers: headers,
      params: method === 'GET' && body ? body : undefined,
      data: method !== 'GET' && body ? body : undefined,
      responseType: 'json',
    });
    return response.data;
  } catch (e) {
    controller.abort();
    return e;
  }
};
