import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';

export const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whiteList: ['newsList'],
};

declare global {
  interface Window {
    __PRELOADED_STATE__: unknown;
  }
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const preloadedState = window.__PRELOADED_STATE__;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
