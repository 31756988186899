import { newsList } from './News/NewsList';
import { Reducer, combineReducers } from 'redux';
import globalStatesReducer from './GlobalStates/GlobalStates';
import newsListReducer from './News/NewsList';
import { IGlobalStates, INewsItem, IPageHeaderState } from './interfaces';
import pageHeaderReducer from './PageHeader/PageHeader';

interface IReducerListInterface {
  globalStates: Reducer<IGlobalStates, any>;
  newsList: Reducer<{ news: Array<INewsItem> | undefined }, any>;
  pageHeader: Reducer<{ props: IPageHeaderState }, any>;
}

const ReducerList: IReducerListInterface = {
  globalStates: globalStatesReducer,
  newsList: newsListReducer,
  pageHeader: pageHeaderReducer,
};

const appReducer = combineReducers(ReducerList);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
