import { Col, Row, Typography } from 'antd';
import { IconBiggerSign } from 'assets/icons/_index';
import { isStringEmpty } from 'helpers/methods';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { IContentNavigation } from 'store/interfaces';

export default function ContentNavigation() {
  const items = useSelector((state: RootState) => state.pageHeader.props.contentNavigation);
  const navigate = useNavigate();
  const { Text } = Typography;
  const handleNavigate = (url: string | undefined) => {
    if (url) {
      navigate(url);
    }
  };
  return (
    <Row justify={'center'}>
      <Col className='navigationContainer' span={24}>
        <Row>
          <Col>
            <Text onClick={() => handleNavigate('/')} className='navigationContainer-text'>
              Anasayfa
            </Text>
          </Col>
        </Row>
        {items.map((item: IContentNavigation, index: number) => {
          if (index != 0) {
            return (
              !isStringEmpty(item.name) && (
                <Row key={item.name}>
                  <Col>
                    <img className='navigationContainer-icon' src={IconBiggerSign} alt='' />
                  </Col>
                  <Col>
                    <span
                      onClick={() => handleNavigate(item.redirectUrl)}
                      className='navigationContainer-text'
                    >
                      {item.name}
                    </span>
                  </Col>
                </Row>
              )
            );
          }
        })}
      </Col>
    </Row>
  );
}
