import { createSlice } from '@reduxjs/toolkit';

export const mobilDeviceCheckerSlice = createSlice({
  name: 'mobilDeviceChecker',
  initialState: {
    isMobilDevice: false,
    isSideMenuOpen: false,
  },
  reducers: {
    setSideMenuOpen: (state, action) => {
      state.isSideMenuOpen = action.payload;
    },
    checkDevice: (state) => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        state.isMobilDevice = true;
      } else {
        state.isMobilDevice = false;
      }
    },
  },
});
export const { checkDevice, setSideMenuOpen } = mobilDeviceCheckerSlice.actions;
export default mobilDeviceCheckerSlice.reducer;
