import { Outlet } from 'react-router-dom';
import * as Elements from './Elements';
const routes = () => {
  return [
    {
      element: <Outlet />,
      path: '/',
      children: [
        { index: true, element: <Elements.HomePage /> },
        { path: '/*', element: <Elements.Projects /> },
        { path: '/not-found', element: <Elements.NotFound /> },
      ],
    },
  ];
};

export default routes;
