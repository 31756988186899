import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import './styles/main.less';
import './styles/antd.less';
import 'moment/locale/tr';
import moment from 'moment';
import tr_TR from 'antd/lib/locale/tr_TR';
import { BrowserRouter } from 'react-router-dom';
moment.locale('tr');
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from 'store';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={tr_TR}>
          <App />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);
