import { Row, Col, Typography } from 'antd';
import { ISideBarItem } from 'store/interfaces';

export default function ContentSideBar(props: {
  items: any[];
  selectedItem: any | undefined;
  selectedSubItem: any | undefined;
  handleItemChange?: (item: any, index: number) => void;
  handleSubItemChange?: (item: any, index: number, subIndex: number) => void;
}) {
  const { Text } = Typography;
  const { handleItemChange, handleSubItemChange, items, selectedItem, selectedSubItem } = props;
  const handleSubItemSelection = (item: ISideBarItem, index: number, subIndex: number) => {
    if (handleSubItemChange) {
      handleSubItemChange(item, index, subIndex);
    }
  };
  const handleItemSelection = (item: ISideBarItem, index: number) => {
    if (handleItemChange) {
      handleItemChange(item, index);
    }
  };
  return (
    <Row gutter={[0, 32]} className='mt-22'>
      {items &&
        items.map((item: ISideBarItem, index: number) => {
          return (
            <Col key={item.name} span={24}>
              {item.name == selectedItem?.name ? (
                <Row gutter={[0, 16]}>
                  <Col onClick={() => handleItemSelection(item, index)} span={24}>
                    <Row justify={'space-between'}>
                      <Col className='bg-primary-red' span={1} />
                      <Col span={22}>
                        <Text className='text text-primary-red fs-16-px fw-700 cursor-pointer'>
                          {item.name}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  {item.subItems ? (
                    <Col span={24}>
                      <Row justify={'space-between'}>
                        <Col span={1} className='sideBarSubItemGap' />
                        <Col span={22}>
                          <Row gutter={[0, 32]}>
                            {item.subItems.map((subItem: ISideBarItem, i: number) => (
                              <Col
                                onClick={() => handleSubItemSelection(subItem, index, i)}
                                key={subItem.name}
                                span={24}
                              >
                                {subItem.name == selectedSubItem?.name ? (
                                  <span className='text fs-16-px text-primary-red fw-700'>
                                    {subItem.name}
                                  </span>
                                ) : (
                                  <span className='text fs-16-px text-semi-transparent-dark-blue fw-400 cursor-pointer'>
                                    {subItem.name}
                                  </span>
                                )}
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                </Row>
              ) : (
                <Row>
                  <Col onClick={() => handleItemSelection(item, index)} span={24}>
                    <Row justify={'space-between'}>
                      <Col span={22}>
                        <span className='text text-semi-transparent-dark-blue fs-16-px fw-700 cursor-pointer'>
                          {item.name}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          );
        })}
    </Row>
  );
}
