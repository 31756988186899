import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { siyahArkaPlanHeader } from 'assets/images/_index';
import { IPageHeaderState } from 'store/interfaces';

export const PageHeaderSlice = createSlice({
  name: 'pageHeader',
  initialState: {
    props: {
      imageUrl: siyahArkaPlanHeader,
      text: '',
      contentNavigation: [],
    } as IPageHeaderState,
  },
  reducers: {
    setPageHeaderProps: (state, action: PayloadAction<IPageHeaderState>) => {
      state.props = {
        ...action.payload,
        contentNavigation: [
          { name: 'Anasayfa', redirectUrl: '/' },
          ...action.payload.contentNavigation,
        ],
      };
    },
  },
});
export const { setPageHeaderProps } = PageHeaderSlice.actions;
export default PageHeaderSlice.reducer;
