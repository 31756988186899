import { Col, Image, Row, Typography } from 'antd';
import { IconFooterFacebook, IconFooterInstagram } from 'assets/icons/_index';
import { footerImage } from 'assets/images/_index';
import { redirectInNewWindow } from 'helpers/methods';
import React from 'react';

export default function CustomFooter() {
  const { Text } = Typography;
  return (
    <Row className='footer'>
      <Col span={24}>
        <Row className='footer-top' align={'middle'}>
          <Col xxl={18} xl={18} lg={18} md={24}>
            <Image
              preview={false}
              src={footerImage}
              alt='footerImage'
              className='footer-top-image'
            />
          </Col>
          <Col xxl={6} xl={6} lg={6} md={24}>
            <Row className='footer-top-socialMedia' gutter={[0, 32]}>
              <Col span={24}>
                <Text className='footer-top-socialMedia-text'>Bir Usta Bin Usta’yı Takip Edin</Text>
              </Col>
              <Col span={24}>
                <Row className='footer-top-socialMedia-icons' justify={'space-evenly'}>
                  <Col>
                    <Row align={'middle'}>
                      <Col
                        onClick={() =>
                          redirectInNewWindow('https://www.instagram.com/birustabinusta/')
                        }
                        className='footer-top-socialMedia-icons-icon'
                      >
                        <Image
                          preview={false}
                          src={IconFooterInstagram}
                          alt='IconFooterInstagram'
                          className='footer-top-socialMedia-icons-icon-image instagram'
                        />
                      </Col>
                      <Col>
                        <Text className='footer-top-socialMedia-icons-text'>Instagram</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row align={'middle'}>
                      <Col
                        onClick={() =>
                          redirectInNewWindow('https://www.facebook.com/BirUstaBinUsta/')
                        }
                        className='footer-top-socialMedia-icons-icon'
                      >
                        <Image
                          preview={false}
                          src={IconFooterFacebook}
                          alt='IconFooterFacebook'
                          className='footer-top-socialMedia-icons-icon-image facebook'
                        />
                      </Col>
                      <Col>
                        <Text className='footer-top-socialMedia-icons-text'>Facebook</Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row className='footer-bottom' justify={'center'} align={'middle'}>
          <Col span={20}>
            <Text className='footer-bottom-text'>
              © Copyright 2022 Anadolu Anonim Türk Sigorta Şirketi
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
