import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { INavBarListRequest, INavBarListResponse } from 'store/interfaces';
import { createApiCall as createApiCall } from 'store/service';

export const navbar = createAsyncThunk(
  'navbar',
  async ({ ...props }: INavBarListRequest, thunkAPI) => {
    const { onSuccess, onError, body } = props;
    try {
      const response: INavBarListResponse = await createApiCall({
        method: 'POST',
        url: 'nav-bar/list',
        body,
      });
      response.success ? onSuccess(response.data) : onError(response);
      return response.data;
    } catch (error: unknown) {
      onError();
      console.log('navbar CreateAsyncThunk', error);
      thunkAPI.rejectWithValue(error);
    }
  },
);
//create navbar slice start
export const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    navbarItems: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(navbar.fulfilled, (state, action: any) => {
      state.navbarItems = action.payload;
    });
  },
});
// create navbar slice end

export default navbarSlice.reducer;
