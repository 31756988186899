import { Button, Col, Image, Row, Carousel, Typography } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { IconImageArrowLeft, IconImageArrowRight } from 'assets/icons/_index';
import {
  headerImage1,
  headerImage2,
  headerImage3,
  headerImage4,
  headerImage5,
} from 'assets/images/_index';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export default function HomePageHeader() {
  const carouselRef = useRef<CarouselRef>(null);
  const { isMobilDevice } = useSelector((state: RootState) => state.globalStates);
  const { Text } = Typography;
  const headerImages = [
    {
      id: 1,
      imageUrl: headerImage1,
    },
    {
      id: 2,
      imageUrl: headerImage2,
    },
    {
      id: 3,
      imageUrl: headerImage3,
    },
    {
      id: 4,
      imageUrl: headerImage4,
    },
    {
      id: 5,
      imageUrl: headerImage5,
    },
  ];
  return (
    <Row
      className={`pageHeader${isMobilDevice ? ' mobile' : ''}`}
      justify={'space-evenly'}
      align={'middle'}
    >
      <Col span={1}>
        <Image
          preview={false}
          src={IconImageArrowLeft}
          alt='container-content-arrow-left'
          className='container-content-arrowContainer-arrows-arrow'
          onClick={() => carouselRef?.current?.prev()}
        />
      </Col>
      <Col className='container' span={21}>
        <Carousel
          dots={{ className: 'container-images-dots' }}
          ref={carouselRef}
          className='container-images'
        >
          {headerImages.map((image) => (
            <Image
              key={image.id}
              preview={false}
              src={image.imageUrl}
              alt='pageHeader-images-container-image'
              className='container-images-image'
            />
          ))}
        </Carousel>
        <Row className='container-content'>
          <Col span={24}>
            <Row className='container-content-textContainer'>
              <Col span={24}>
                <Text className='container-content-textContainer-text'>
                  BİR USTA BİN USTA PROJEMİZİ, İNSTAGRAM’DAN DA TAKİP EDEBİLİRSİNİZ.
                </Text>
              </Col>
              <Col span={24}>
                <Row justify={'end'}>
                  <Button className='container-content-textContainer-button'>Detaylı Bilgi</Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={1}>
        <Image
          preview={false}
          src={IconImageArrowRight}
          alt='container-content-arrowContainer-arrow-right'
          className='container-content-arrowContainer-arrows-arrow'
          onClick={() => carouselRef?.current?.next()}
        />
      </Col>
    </Row>
  );
}
