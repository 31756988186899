import { Row, Col } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { IDropdownItem } from 'store/interfaces';
import DropdownButton from '../DropdownButton/DropdownButton';
import HtmlStringRenderer from 'components/HtmlStringRenderer';
interface Props {
  items: any[];
}

const CustomDropdown: FC<Props> = ({ items }) => {
  const [dropdownItems, setDropdownItems] = useState<IDropdownItem[]>();
  useEffect(() => {
    setDropdownItems(
      items.map((item: any) => {
        return {
          ...item,
          isOpen: item.isOpen == undefined ? false : item.isOpen,
        };
      }),
    );
  }, [items]);
  const handleDropdownClick = (index: number) => {
    if (dropdownItems) {
      const temp = [...dropdownItems];
      temp[index].isOpen = !temp[index].isOpen;
      setDropdownItems(temp);
    }
  };
  return (
    <Row gutter={[0, 18]} className='contentContainer-bottom mb-100-px'>
      {dropdownItems?.map((item: IDropdownItem, index: number) => (
        <Col key={item.name} span={24}>
          <Row className='dropdownContainer'>
            <Col span={24}>
              <DropdownButton
                onClick={() => handleDropdownClick(index)}
                isOpen={item.isOpen}
                text={item.name}
              />
            </Col>
            <Col span={24}>
              <Row className='pt-p-1'>
                {item.isOpen ? <HtmlStringRenderer html={item.content} /> : null}
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default CustomDropdown;
