import { Row, Col, Typography } from 'antd';
import { IProductDetails, ISubProductDetails } from 'store/interfaces';

export default function MobileProductNavigation(props: {
  selectedProduct: IProductDetails;
  selectedSubProduct: ISubProductDetails;
  setSelectedSubProduct: (subProduct: ISubProductDetails) => void;
}) {
  const { Text } = Typography;
  const { selectedProduct, selectedSubProduct } = props;
  console.log(selectedProduct.productDetails);
  console.log(selectedSubProduct);
  return (
    <Row className='mobileProductNavigationContainer' justify={'space-between'}>
      {selectedProduct.productDetails.map((item: ISubProductDetails) => (
        <Col
          onClick={() => props.setSelectedSubProduct(item)}
          className={`mobileProductNavigationContainer-button${
            selectedSubProduct.id == item.id ? ' active' : ''
          }`}
          key={item.id}
        >
          <Text>{item.name}</Text>
        </Col>
      ))}
    </Row>
  );
}
