export const baseRequestBody = {
  webSite: 'KWS_BIRUSTABINUSTA',
  language: 'TR',
};

// unused constants
export const regexDataSource = {
  abortSpace: '[^ ]',
  abortSpecialCharacters: '[a-zA-ZıIiİçÇşŞğĞÜüÖö0-9]',
  abortAlphabet: '[0-9]',
  abortAlphabetExceptComma: '[0-9,]',
  abortNumbers: '[a-zA-ZıIiİçÇşŞğĞÜüÖö^ ]',
  abortSpaceAndSpecialCharacters: '[a-zA-Z0-9]',
  abortSpaceAndSpecialCharactersExceptUnderscore: '[a-zA-Z0-9_ı]',
};
export const customerTypeIds = {
  tckn: 'tckn',
  vkn: 'vkn',
  ykn: 'ykn',
  kkn: 'kkn',
  skn: 'skn',
  pol: 'pol',
  externalNumber: 'externalNumber',
};

export const customerTypes = {
  tckn: 'TCKN',
  vkn: 'VKN',
  ykn: 'YKN',
  kkn: 'KKN',
  skn: 'SKN',
  pol: 'POL',
  externalNumber: 'Müşteri No',
};

export const customerTypeValues = {
  tckn: 'TCKN',
  vkn: 'VKN',
  ykn: 'YKN',
  kkn: 'KKN',
  skn: 'SKN',
  externalNumber: 'EXTERNAL_NUMBER',
};

export const monthsTR = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
export const daysTr = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
export const BOARD_MEMBERS_TYPES = {
  boardMembers: 'Yönetim Kurulu Üyeleri',
  executiveBoardMembers: 'İcra Kurulu Üyeleri',
};
